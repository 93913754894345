import type {
  GetEventProgramQuery,
  GetEventProgramQueryVariables
} from './getEventProgram.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetEventProgram($eventId: ID!) {
    event(id: $eventId) {
      id
      subEvents {
        id
        name
        startDate
        startTime
        endDate
        endTime
      }
    }
  }
`

export type SubEvent = NonNullable<GetEventProgramQuery['event']>['subEvents'][0]

export const getProgram = (eventId: string) => {
  const { loading, result } = useQuery<GetEventProgramQuery, GetEventProgramQueryVariables>(query, {
    eventId
  })

  const subEvents = computed(() => result.value?.event?.subEvents || [])

  return { loading, subEvents }
}
