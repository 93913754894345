import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import type {
  GetReleaseNotesQuery,
  GetReleaseNotesQueryVariables
} from './getAllReleaseNotes.gql.generated'
import { computed } from 'vue'
import { currentPagination } from '@/services/utils/current-query-params'
import type { PaginationArguments } from '@/types/pagination'

const query = gql`
  query GetReleaseNotes($pagination: Pagination) {
    releaseNotes(pagination: $pagination) {
      collection {
        id
        title
        notes
        date
      }
      meta {
        items
        page
        pages
        series
        total
      }
    }
  }
`

export type ReleaseNote = GetReleaseNotesQuery['releaseNotes']['collection'][0]

export const getReleaseNotes = ({
  pagination = currentPagination()
}: {
  pagination?: PaginationArguments
}) => {
  const { result, loading, refetch } = useQuery<
    GetReleaseNotesQuery,
    GetReleaseNotesQueryVariables
  >(query, { pagination })

  const notes = computed(() => result.value?.releaseNotes?.collection || [])

  const meta = computed(() => result.value?.releaseNotes?.meta)

  return { notes, loading, refetch, pagination: meta }
}
